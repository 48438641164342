<template>
  <v-container>
    <v-card class="mb-4" rounded="lg">
      <v-card-text>
        <v-row no-gutters>
          <v-col sm="6" cols="12"
            ><managed-residences-select v-model="selectedResidence"></managed-residences-select
          ></v-col>
          <v-col sm="6" cols="12"><client-years-select v-model="selectedYear"></client-years-select></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="overflow-hidden" rounded="lg" v-if="!$apollo.queries.studentYears.loading">
      <base-table
        v-if="selectedResidence && selectedYear && !$apollo.queries.studentYears.loading"
        :items="studentYears"
        :headers="headers"
        view-only
        :title="$tc('models.studenteducation', 2)"
      >
        <template v-slot:[`tuition_verified_status`]="{ item }">
          <v-tooltip v-if="item.tuition_verified_at" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="mr-2" color="success" v-bind="attrs" v-on="on">
                {{ $t("studentYear.tuition_verified") }}
              </v-chip>
            </template>
            <span
              >{{ moment(item.tuition_verified_at).format("l") }} -
              {{ item.tuitionVerifiedBy.complete_name }}</span
            >
          </v-tooltip>
          <v-chip class="mr-2" v-else color="warning">
            {{ $t("waiting_for_verification") }}
          </v-chip>
        </template>
        <template v-slot:goTo="{ item }">
          <v-btn
            :to="{
              name: 'UserProfileYearTab',
              hash: '#student-year',
              params: { student_year_id: item.id, id: item.student.user.id, tab: '4' },
            }"
            icon
            text
            ><v-icon>mdi-eye-arrow-right</v-icon></v-btn
          >
        </template>
      </base-table>
    </v-card>
    <v-skeleton-loader type="table" v-else />
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import BaseTable from "../tables/BaseTable.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import moment from "moment";

export default {
  components: { BaseTable, ManagedResidencesSelect, ClientYearsSelect },
  name: "StudentAdministrationSection",

  data() {
    return {
      selectedResidence: null,
      selectedYear: null,
      studentYears: [],
      headers: [
        {
          text: this.$t("user.surname"),
          value: "surname",
        },
        {
          text: this.$t("user.name"),
          value: "name",
        },
        {
          text: this.$t("tuitionFormula.name"),
          value: "tuitionFormula.name",
        },
        {
          text: this.$t("studentYear.tuition"),
          value: "tuition",
          type: "currency",
        },
        {
          text: this.$t("studentYear.tuition_verified_status"),
          value: "tuition_verified_status",
          type: "template",
        },
        {
          value: "goTo",
          type: "template",
        },
      ],
      moment,
    };
  },

  apollo: {
    studentYears: {
      query: gql`
        query StudentYears($year_id: ID!, $residence_id: ID!) {
          residence(id: $residence_id) {
            id
            studentYears(year_id: $year_id, orderBy: { user: { column: NAME, aggregate: MAX }, order: ASC }) {
              id
              name
              surname
              tuitionFormula {
                id
                name
              }
              tuition
              tuition_verified_at
              tuitionVerifiedBy {
                id
                complete_name
              }
              student {
                id
                user {
                  id
                }
              }
            }
          }
        }
      `,
      update: (data) => data.residence.studentYears,
      variables() {
        return {
          year_id: this.selectedYear?.id,
          residence_id: this.selectedResidence?.id,
        };
      },
      skip() {
        return !this.selectedResidence || !this.selectedYear;
      },
    },
  },
};
</script>
