<template>
  <v-container>
    <v-card class="mb-4" rounded="lg">
      <v-card-text>
        <v-row no-gutters>
          <v-col sm="6" cols="12"
            ><managed-residences-select v-model="selectedResidence"></managed-residences-select
          ></v-col>
          <v-col sm="6" cols="12"><client-years-select v-model="selectedYear"></client-years-select></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="overflow-hidden" rounded="lg" v-if="!$apollo.queries.studentYears.loading">
      <base-table
        v-if="selectedResidence && selectedYear && !$apollo.queries.studentYears.loading"
        :items="studentYears"
        :headers="headers"
        view-only
        :title="$tc('models.studenteducation', 2)"
      >
        <template v-slot:goTo="{ item }">
          <v-btn
            :to="{
              name: 'UserProfileYearTab',
              hash: '#student-year',
              params: { student_year_id: item.id, id: item.student.user.id, tab: '2' },
            }"
            icon
            text
            ><v-icon>mdi-eye-arrow-right</v-icon></v-btn
          >
        </template>
        <template v-slot:[`total_presences`]="{ item }">
          <v-chip class="mr-2" :color="item.has_reached_goal_time ? 'success' : 'warning'">
            {{ formatTotalPresences(item.total_presences) }}
          </v-chip>
        </template>
      </base-table>
    </v-card>
    <v-skeleton-loader type="table" v-else />
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import BaseTable from "../tables/BaseTable.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import moment from "moment";

export default {
  components: { BaseTable, ManagedResidencesSelect, ClientYearsSelect },
  name: "StudentEducationSection",

  data() {
    return {
      selectedResidence: null,
      selectedYear: null,
      studentYears: [],
      headers: [
        {
          text: this.$t("user.surname"),
          value: "surname",
        },
        {
          text: this.$t("user.name"),
          value: "name",
        },
        {
          text: this.$t("studentYear.goal_time"),
          value: "goal_time",
        },
        {
          text: this.$t("studentYear.total_presences"),
          value: "total_presences",
          type: "template",
        },
        {
          value: "goTo",
          type: "template",
        },
      ],
      moment,
    };
  },

  apollo: {
    studentYears: {
      query: gql`
        query StudentYears($year_id: ID!, $residence_id: ID!) {
          residence(id: $residence_id) {
            id
            studentYears(year_id: $year_id, orderBy: { user: { column: NAME, aggregate: MAX }, order: ASC }) {
              id
              name
              surname
              goal_time
              has_reached_goal_time
              total_presences
              student {
                id
                user {
                  id
                }
              }
            }
          }
        }
      `,
      update: (data) => data.residence.studentYears,
      variables() {
        return {
          year_id: this.selectedYear?.id,
          residence_id: this.selectedResidence?.id,
        };
      },
      skip() {
        return !this.selectedResidence || !this.selectedYear;
      },
    },
  },

  methods: {
    formatTotalPresences: (value) => {
      return parseInt(value / 3600).toString() + "h " + ((value / 60) % 60).toString() + "m";
    },
  },
};
</script>
