<template>
  <v-container>
    <v-card class="mb-4" rounded="lg">
      <v-card-text>
        <v-row no-gutters>
          <v-col sm="6" cols="12"
            ><managed-residences-select v-model="selectedResidence"></managed-residences-select
          ></v-col>
          <v-col sm="6" cols="12"><client-years-select v-model="selectedYear"></client-years-select></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="overflow-hidden" rounded="lg" v-if="!$apollo.queries.studentYears.loading">
      <base-table
        v-if="selectedResidence && selectedYear && !$apollo.queries.studentYears.loading"
        :items="studentYears"
        :headers="headers"
        view-only
        :title="$tc('models.studentacademic', 2)"
      >
        <template v-slot:top>
          <v-card-text>
            <span class="text-body-2 font-weight-bold"> {{ $t("total_description") }} </span>
          </v-card-text>
          <v-divider></v-divider>
        </template>

        <template v-slot:[`academic_profile_verified_status`]="{ item }">
          <v-tooltip v-if="item.academic_profile_verified_at" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip small class="mr-2" color="success" v-bind="attrs" v-on="on">
                {{ $t("verified") }}
              </v-chip>
            </template>
            <span
              >{{ moment(item.academic_profile_verified_at).format("l") }} -
              {{ item.academicProfileVerifiedBy.complete_name }}</span
            >
          </v-tooltip>
          <v-chip small class="mr-2" v-else color="warning">
            {{ $t("waiting_for_verification") }}
          </v-chip>
        </template>

        <template v-slot:[`primaryCareerYear.arithmetic_average`]="{ item }">
          {{ formatters.decimalNumber(item.primaryCareerYear?.arithmetic_average) }} ({{
            formatters.decimalNumber(item.primaryCareerYear?.career.arithmetic_average)
          }})
        </template>

        <template v-slot:[`primaryCareerYear.weighted_average`]="{ item }">
          {{ formatters.decimalNumber(item.primaryCareerYear?.weighted_average) }} ({{
            formatters.decimalNumber(item.primaryCareerYear?.career.weighted_average)
          }})
        </template>

        <template v-slot:[`primaryCareerYear.exams_count`]="{ item }">
          {{ item.primaryCareerYear?.exams_count }} ({{ item.primaryCareerYear?.career.exams_count }})
        </template>

        <template v-slot:[`primaryCareerYear.partial_cfu`]="{ item }">
          {{ item.primaryCareerYear?.partial_cfu }} ({{ item.primaryCareerYear?.cfu }})
        </template>

        <template v-slot:[`primaryCareerYear.gradeReference.grade_reference`]="{ item }">
          <v-chip class="mr-2" :color="item.primaryCareerYear?.has_average_above_reference ? 'success' : 'warning'">
            {{ formatters.decimalNumber(item.primaryCareerYear?.gradeReference.grade_reference) }}
          </v-chip>
        </template>

        <template v-slot:acknowledgment="{ item }">
          <div class="d-flex justify-center">
            <v-icon
              :color="item.primaryCareerYear?.has_average_above_reference ? 'success' : 'warning'"
              small
              class="mr-2"
            >
              mdi-circle
            </v-icon>
          </div>
        </template>

        <template v-slot:goTo="{ item }">
          <v-btn
            :to="{
              name: 'UserProfileYearTab',
              hash: '#student-year',
              params: { student_year_id: item.id, id: item.student.user.id, tab: '1' },
            }"
            icon
            text
            small
            ><v-icon medium>mdi-eye-arrow-right</v-icon></v-btn
          >
        </template>
      </base-table>
    </v-card>
    <v-skeleton-loader type="table" v-else />
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import BaseTable, { formatters } from "../tables/BaseTable.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import moment from "moment";

export default {
  components: { BaseTable, ManagedResidencesSelect, ClientYearsSelect },
  name: "StudentAcademicSection",

  data() {
    return {
      selectedResidence: null,
      selectedYear: null,
      studentYears: [],
      headers: [
        {
          text: this.$t("user.surname"),
          value: "surname",
        },
        {
          text: this.$t("user.name"),
          value: "name",
        },
        {
          text: this.$t("acknowledgment"),
          value: "acknowledgment",
          type: "template",
          sortable: false,
        },
        {
          text: this.$t("careerYear.exams_count"),
          value: "primaryCareerYear.exams_count",
          type: "template",
        },
        {
          text: this.$t("careerYear.cfu"),
          value: "primaryCareerYear.partial_cfu",
          type: "template",
        },
        {
          text: this.$t("gradeReference.grade_reference"),
          value: "primaryCareerYear.gradeReference.grade_reference",
          type: "decimalNumber",
        },
        {
          text: this.$t("careerYear.arithmetic_average"),
          value: "primaryCareerYear.arithmetic_average",
          type: "template",
        },
        {
          text: this.$t("careerYear.weighted_average"),
          value: "primaryCareerYear.weighted_average",
          type: "template",
        },
        {
          text: this.$tc("models.studentacademic"),
          value: "academic_profile_verified_status",
          type: "template",
          sortable: false,
        },
        {
          value: "goTo",
          type: "template",
          sortable: false,
        },
      ],
      moment,
      formatters,
    };
  },

  apollo: {
    studentYears: {
      query: gql`
        query StudentYears($year_id: ID!, $residence_id: ID!) {
          residence(id: $residence_id) {
            id
            studentYears(year_id: $year_id, orderBy: { user: { column: NAME, aggregate: MAX }, order: ASC }) {
              id
              name
              surname
              primaryCareerYear {
                id
                arithmetic_average
                weighted_average
                partial_cfu
                cfu
                has_average_above_reference
                exams_count(done: true)
                gradeReference {
                  id
                  grade_reference
                }
                career {
                  id
                  arithmetic_average
                  weighted_average
                  exams_count(done: true)
                }
              }
              academic_profile_verified_at
              academicProfileVerifiedBy {
                id
                complete_name
              }
              student {
                id
                user {
                  id
                }
              }
            }
          }
        }
      `,
      update: (data) => data.residence.studentYears,
      variables() {
        return {
          year_id: this.selectedYear?.id,
          residence_id: this.selectedResidence?.id,
        };
      },
      skip() {
        return !this.selectedResidence || !this.selectedYear;
      },
    },
  },
};
</script>
