<template>
  <admin-layout
    :title="$t('navigation.dashboard')"
    :value="section"
    @input="$route.push({ name: 'Administration', params: { section: $event } })"
    :drawerItems="drawerItems"
    routeName="Dashboard"
  >
    <template v-slot:studenteducation>
      <student-education-section></student-education-section>
    </template>
    <template v-slot:studentacademic>
      <student-academic-section></student-academic-section>
    </template>
    <template v-slot:studentadministration>
      <student-administration-section></student-administration-section>
    </template>
  </admin-layout>
</template>

<script>
import { ucFirst } from "../apollo/helpers";
import StudentEducationSection from "../components/dashboard/StudentEducationSection.vue";
import StudentAdministrationSection from "../components/dashboard/StudentAdministrationSection.vue";
import AdminLayout from "../layouts/AdminLayout.vue";
import StudentAcademicSection from "../components/dashboard/StudentAcademicSection.vue";

export default {
  components: { AdminLayout, StudentEducationSection, StudentAdministrationSection, StudentAcademicSection },
  props: {
    section: {
      type: String,
    },
  },
  data() {
    return {
      drawerItems: [
        { title: this.$tc("models.studentacademic", 2), section: "studentacademic", icon: "mdi-badge-account" },
        { title: this.$tc("models.studenteducation", 2), section: "studenteducation", icon: "mdi-book" },
        {
          title: this.$tc("models.studentadministration", 2),
          section: "studentadministration",
          icon: "mdi-cash-multiple",
        },
      ],
      mini: true,
      drawer: true,
    };
  },
  methods: {
    ucFirst,
  },
};
</script>
